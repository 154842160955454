<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-toolbar color="#002441" dark>
            <v-toolbar-title> Receipt From </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-scale-transition>
              <v-btn icon @click="$router.go(-1)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-scale-transition>
          </v-toolbar>
          <v-card-text class="pa-10">
            <v-row justify="space-between">
              <div class="d-flex">
                <div>
                  <!-- <v-img src="@/assets/logo.png"></v-img> -->
                </div>
                <!-- <div class="ml-5">
                  Total Ecommerce Solution
                  <div class="d-flex my-2">
                    <v-icon>mdi-map-marker</v-icon>
                    <div class="ml-5">Gulfesha Plaza , Moghbazar</div>
                  </div>
                  <div class="d-flex my-2">
                    <v-icon>mdi-email</v-icon>
                    <div class="ml-5">intense@gmail.com</div>
                  </div>
                  <div class="d-flex my-2">
                    <v-icon>mdi-phone</v-icon>
                    <div class="ml-5">01757047579</div>
                  </div>
                </div> -->
              </div>
              <div>
                <p v-if="transaction.created_date">
                  {{ new Date(transaction.created_date).toLocaleString() }}
                </p>
                <div
                  v-if="
                    transaction.status &&
                    transaction.status.toLowerCase() == 'pending'
                  "
                >
                  <v-btn
                    color="#002441"
                    width="100%"
                    @click.prevent="onApprove"
                    class="mb-2"
                    elevation="0"
                    >Approve</v-btn
                  >

                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        width="100%"
                        elevation="0"
                      >
                        Reject
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title
                        class="white--text py-4"
                        style="background-color: #002441"
                      >
                        Reject Transaction
                      </v-card-title>

                      <v-card-text>
                        <v-row>
                          <p>Please give a note why you want to reject.</p>
                        </v-row>

                        <v-row>
                          <v-textarea
                            outlined
                            name="input-7-4"
                            color="#002441"
                            v-model="cancellation_note"
                          ></v-textarea>
                        </v-row>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          color="#002441"
                          @click="dialog = false"
                          elevation="0"
                        >
                          Close
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="red"
                          @click="onReject"
                          elevation="0"
                          :disabled="!cancellation_note.trim().length"
                        >
                          Reject
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </v-row>

            <v-row class="mt-5">
              <v-col class="grey lighten-3 mr-2">
                <v-row>
                  <v-col>
                    <div>Receipt ID</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.receipt_id }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="grey lighten-3">
                <v-row>
                  <v-col>
                    <div>Account No</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.account_no }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <div>Reference No</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.reference_number }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col>
                    <div>Bank Name</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.bank_name }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="grey lighten-3 mr-2">
                <v-row>
                  <v-col>
                    <div>Status</div>
                  </v-col>
                  <v-col>
                    <div class="font-weight-bold">
                      : {{ transaction.status }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="grey lighten-3">
                <v-row>
                  <v-col>
                    <div>Branch Name</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.bank_branch }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <div>Deposite Type</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.deposit_type }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col>
                    <div>Deposite Type ID</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.deposit_type_id }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="grey lighten-3 mr-2">
                <v-row>
                  <v-col>
                    <div>Transaction Form</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.deposit_type_id }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="grey lighten-3">
                <v-row>
                  <v-col>
                    <div>Transaction Form</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.deposit_type_id }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <div>Amount</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.amount }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <!-- <v-row>
                  <v-col>
                    <div>Amount in Words</div>
                  </v-col>
                  <v-col>
                    <div>: 8735y2374</div>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>

            <v-row>
              <v-col class="grey lighten-3 mr-2" v-if="transaction.name">
                <v-row>
                  <v-col>
                    <div>Name</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.name }}</div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="grey lighten-3" v-if="transaction.contact_number">
                <v-row>
                  <v-col>
                    <div>Contact No</div>
                  </v-col>
                  <v-col>
                    <div>: {{ transaction.contact_number }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="transaction.note">
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <div class="font-weight-bold">Note :</div>
                  </v-col>
                  <v-col>
                    <div>{{ transaction.note }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="transaction.cancellation_note">
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <div class="font-weight-bold">Cancel Reason :</div>
                  </v-col>
                  <v-col>
                    <div>{{ transaction.cancellation_note }}</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row justify="center" class="mt-10">
              <div>
                <v-img :src="transaction.image_url" width="100%"></v-img>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" color="white" centered>
      <p class="black--text mb-0">{{ text }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      transaction: {},
      text: "",
      snackbar: false,
      dialog: false,
      cancellation_note: "",
    };
  },
  methods: {
    getTransactionDetails() {
      // console.log(this.$route.query.TID);
      if (this.$route.query.TID) {
        axios
          .get(`impress/show_specific_transaction/${this.$route.query.TID}/`)
          .then((response) => {
            // console.log({ response });
            if (response.data.success) {
              this.transaction = response.data.data;
            }
          })
          .catch((err) => {
            // handle error
          });
      }
    },

    onApprove() {
      axios
        .get(`impress/approve_transaction/${this.transaction.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.transaction.status = "Approved";
            this.createSnackbar(response.data.message);
          } else {
            this.createSnackbar("Something went wrong !");
          }
        })
        .catch((err) => {
          // Handle error
          this.createSnackbar("Something went wrong !");
        });
    },

    onReject() {
      this.dialog = false;
      axios
        .post(`impress/cancel_transaction/${this.transaction.id}/`, {
          cancellation_note: this.cancellation_note,
        })
        .then((response) => {
          if (response.data.success) {
            this.transaction.status = "Canceled";
            this.createSnackbar(response.data.message);
          } else {
            this.createSnackbar("Something went wrong !");
          }

          this.cancellation_note = "";
        })
        .catch((err) => {
          // Handle error
          this.createSnackbar("Something went wrong !");
          this.cancellation_note = "";
        });
    },

    // Create sanckbar
    createSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
  },
  created() {
    this.getTransactionDetails();
  },
};
</script>


<style>
.custom-header {
  background-color: #e5f1fb;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

